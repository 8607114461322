import React from 'react';
import Link from '../atoms/link';
import lock from '../../images/lock.svg';
import logo from '../../images/opf/logo.png';

const Header = ({
	type='main',
	content
}) => {

	const secureLock = (type === 'main') ? <img src={lock} alt={content.rightColumnText} /> : null;
	
	const leftColumn = (
		<div className='l-grid__col-xs-7 l-grid__col-md-8 co-header__logo'>
			{/* <Link url='/' type="image"> */}
				<img src={logo} alt='One Park Financial' />
			{/* </Link> */}
		</div>
	);
	
	const rightColumn = (
		<div className='l-grid__col-xs-5 l-grid__col-md-4 co-header__right'>
			{secureLock}
			<span>{content.rightColumnText}</span>
		</div>
	);

	return (
		<div className='co-header'>
			<div className='co-header__wrapper'>
				<div className='co-header__main-menu'>
					<div className='l-container'>
						<div className='l-grid__row'>
							{leftColumn}
							{rightColumn}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
